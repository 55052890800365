import React, { useEffect } from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

import "./measure-what-matters.module.scss"
import VerticalCarousel from "../components/vertical-carousel/vertical-carousel"
import ExplorerView from "../components/explorer-view/explorer-view"

const icons = {
  measure: require("../images/measure/measure-cover.svg"),
  health: require("../images/measure/health-carousel.svg"),
  strength: require("../images/measure/strength-carousel.svg"),
  homeOffice: require("../images/measure/home-office-carousel.svg"),
  roi: require("../images/measure/roi-carousel.svg"),
  detail: require("../images/measure/detail-carousel.svg"),
  comparison: require("../images/measure/comparison-carousel.svg"),
  pulse: require("../images/measure/pulse-carousel.svg"),
  explorers: require("../images/measure/explorers-circle.svg"),
}

const MeasureWhatMatters = () => {
  const carouselDataMeasure = [
    {
      title: "Health Age​",
      desc:
        "In peak condition for your age? Learn if your health matches your work ambition at personal, team and company levels​.",
      contentImg: icons.health,
      color: "purple",
    },
    {
      title: "​Psychometric strength​",
      desc:
        "Stressed, anxiety and depression materially impact work performance. Get an accurate snapshot of the mental health of your people to help you finetune interventions.​",
      contentImg: icons.strength,
      color: "teal",
    },
    {
      title: "​Home, office, or both?​",
      desc:
        "It’s about people and places. Find the natural performance habitat for your people. Empower productive practice with SHAPE tools to make WFH and WFO equally effective.​",
      contentImg: icons.homeOffice,
      color: "green",
    },
    {
      title: "​What’s your ROI",
      desc:
        "What's the value of productivity at personal, team and company levels? Your SHAPE scores integrate Return on Investment measures to guide where to place priority effort.​",
      contentImg: icons.roi,
      color: "dark-blue",
    },
    {
      title: "​Get all the detail​",
      desc:
        "Dive deeper to analyse every question you answered. Scores help guide what really needs your attention. ​",
      contentImg: icons.detail,
      color: "purple",
    },
    {
      title: "​The real comparison​",
      desc:
        "Get benchmark data that leads to real change. Learn how you compare with your own team and company average scores.​",
      contentImg: icons.comparison,
      color: "dark-blue",
    },
    {
      title: "​Pulse performance​",
      desc:
        "Select your audience and re-survey specific topics you’ve acted upon to keep improving throughout the year​.",
      contentImg: icons.pulse,
      color: "teal",
    },
  ]
  useEffect(() => {
    document.getElementById("service-1").addEventListener("wheel", function(e) {
      snapScrollCustom(e, 1)
    })
    document.getElementById("service-2").addEventListener("wheel", function(e) {
      snapScrollCustom(e, 2)
    })
  }, [])

  const snapScrollCustom = (e, currentIndex) => {
    e.preventDefault()

    let nextSection = null
    if (e.deltaY >= 0) {
      //next section
      nextSection = currentIndex < 3 ? currentIndex + 1 : null
    } else {
      //previous section
      nextSection = currentIndex > 1 ? currentIndex - 1 : null
    }
    if (nextSection === null) {
      return
    }
    let nextSectionObj = document.getElementById(`service-${nextSection}`)
    if (nextSectionObj) {
      nextSectionObj.scrollIntoView({
        behavior: "smooth",
        block: nextSection === 3 ? "start" : "center",
      })
    }
  }
  return (
    <Layout>
      <SEO title="SHAPE AI" />
      <div styleName="hero section" id="service-1">
        <Container styleName="measure-custom-container">
          <Row>
            <Col
              xs={{ order: 1, span: 12 }}
              sm={{ order: 1, span: 6 }}
              styleName="custom-padding"
            >
              <div styleName="flexCenter fadeIn">
                <h1>Measure what matters​</h1>
                <p>
                  Get intelligence that counts. Measure everything about people
                  experience in one place. Make better decisions. ​
                </p>
                <a href="/launch">Launch SHAPE</a>
              </div>
            </Col>
            <Col
              xs={{ order: 12, span: 12 }}
              sm={{ order: 12, span: 6 }}
              styleName="custom-padding"
            >
              <div styleName="fadeIn">
                <img
                  src={icons.measure}
                  alt="SHAPE-service"
                  styleName="measure-hero-image"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div styleName="content">
        <div styleName="section" id="service-2">
          <ExplorerView />
        </div>
        <div id="service-3">
          <div styleName="power-people">
            <div styleName="powerPeopleHeading">
              Power your people to go from <span>good to great</span>
            </div>
            <div styleName="powerPeopleDesc">
              SHAPE is the System for Health, Attendance, Productivity and
              Engagement. It measures all the factors that drive workplace
              productivity. High performance means all these moving parts work
              together in perfect harmony. SHAPE scores these factors and guides
              everyone towards effective action. ​
            </div>
          </div>
          <h1>Action through insights</h1>

          <VerticalCarousel data={carouselDataMeasure} />
        </div>
      </div>
      <CTA />
    </Layout>
  )
}

export default MeasureWhatMatters
