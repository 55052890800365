import React, { useState } from "react"
import "./explorer-view.module.scss"
import PW from "../../images/explorer-view/pw-exp.svg"
import PHY from "../../images/explorer-view/phy-exp.svg"
import PSY from "../../images/explorer-view/psy-exp.svg"
import FOW from "../../images/explorer-view/fow-exp.svg"
import PWE from "../../images/explorer-view/pwe-exp.svg"
import SWE from "../../images/explorer-view/swe-exp.svg"
import BF from "../../images/explorer-view/bf-exp.svg"
import CC from "../../images/explorer-view/cc-exp.svg"
import MS from "../../images/explorer-view/ms-exp.svg"
import JS from "../../images/explorer-view/js-exp.svg"
import EE from "../../images/explorer-view/ee-exp.svg"
import EC from "../../images/explorer-view/ec-exp.svg"
import Logo from "../../images/board-logo.svg"

const ExplorerView = () => {
  const [currentExplorer, setCurrentExplorer] = useState(null)

  const mouseEnterImage = e => {
    let nextExplorer = e.target.alt
    let otherExplorer
    if (nextExplorer !== currentExplorer) {
      let contentText = document.getElementById("explorer-content")
      contentText.style.opacity = 0
      contentText.style.transform = "translateY(5px)"

      setTimeout(() => {
        // setCurrentPage(index)
        setCurrentExplorer(nextExplorer)
        contentText.style.opacity = 1
        contentText.style.transform = "translateY(-5px)"
      }, 300)
    }
    for (let i = 1; i <= 12; i++) {
      if (e.target.id !== `explorer-view-${i}`) {
        otherExplorer = document.getElementById(`explorer-view-${i}`)
        otherExplorer.style.opacity = 0.6
      }
    }
  }
  const mouseLeaveImage = e => {
    let otherExplorer

    for (let i = 1; i <= 12; i++) {
      otherExplorer = document.getElementById(`explorer-view-${i}`)
      otherExplorer.style.opacity = 1
    }
  }

  const onResetExplorer = () => {
    if (currentExplorer !== null) {
      let contentText = document.getElementById("explorer-content")
      contentText.style.opacity = 0
      contentText.style.transform = "translateY(5px)"

      setTimeout(() => {
        // setCurrentPage(index)
        setCurrentExplorer(null)
        contentText.style.opacity = 1
        contentText.style.transform = "translateY(-5px)"
      }, 300)
    }
  }
  const explorersDetails = {
    pw: {
      heading: "Personal Work Ethic",
      desc: "Strong or weak?",
      module: "ABOUT YOU",
    },
    phy: {
      heading: "Physical Health",
      desc: "Optimal, or poor?",
      module: "ABOUT YOU",
    },
    psy: {
      heading: "Psychological Health",
      desc: "Idea, or struggling?",
      module: "ABOUT YOU",
    },
    fow: {
      heading: "Factors Outside Work",
      desc: "In balance, ​or imbalanced?",
      module: "ABOUT YOU",
    },
    pwe: {
      heading: "Physical Work Environment",
      desc: "Helpful, or hindering?",
      module: "THE FACILITIES",
    },
    swe: {
      heading: "Soft Work Environment",
      desc: "Enabling, or disabling?",
      module: "THE FACILITIES",
    },
    bf: {
      heading: "Business Fundamentals",
      desc: "Optimised, or sub-par?",
      module: "THE COMPANY",
    },
    cc: {
      heading: "Corporate Culture",
      desc: "Inspirational, or toxic?",
      module: "THE COMPANY",
    },
    ms: {
      heading: "Management Style",
      desc: "Supportive, or obstructive?",
      module: "YOUR NETWORK",
    },
    js: {
      heading: "Job Satisfaction",
      desc: "Pleased or frustrated?",
      module: "YOUR NETWORK",
    },
    ee: {
      heading: "Employee Engagement",
      desc: "Engaged, or disengaged?",
      module: "YOUR NETWORK",
    },
    ec: {
      heading: "Employee Commitment",
      desc: "Dedicated, or indifferent?",
      module: "YOUR NETWORK",
    },
  }
  return (
    <div styleName="explorer-view-wrapper">
      <div styleName="explorer-view-svg">
        <div styleName="explorer-view-svg-wrapper fadeIn cssanimation">
          <div id="explorer-view-left" styleName="explorer-view-left">
            <img
              src={Logo}
              alt="shape-logo"
              styleName="shape-logo-centered"
              id="explorer-view-logo"
              onMouseOver={e => onResetExplorer()}
            />
            <img
              src={PW}
              alt="pw"
              styleName="explorer-1"
              id="explorer-view-1"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={PHY}
              alt="phy"
              styleName="explorer-2"
              id="explorer-view-2"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={PSY}
              alt="psy"
              id="explorer-view-3"
              styleName="explorer-3"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={FOW}
              alt="fow"
              id="explorer-view-4"
              styleName="explorer-4"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={PWE}
              alt="pwe"
              id="explorer-view-5"
              styleName="explorer-5"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={SWE}
              id="explorer-view-6"
              alt="swe"
              styleName="explorer-6"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
          </div>
          <div id="explorer-view-right" styleName="explorer-view-right">
            <img
              src={EC}
              alt="ec"
              id="explorer-view-12"
              styleName="explorer-12"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={EE}
              alt="ee"
              id="explorer-view-11"
              styleName="explorer-11"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={JS}
              alt="js"
              id="explorer-view-10"
              styleName="explorer-10"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={MS}
              alt="ms"
              id="explorer-view-9"
              styleName="explorer-9"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={CC}
              alt="cc"
              id="explorer-view-8"
              styleName="explorer-8"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
            <img
              src={BF}
              alt="bf"
              id="explorer-view-7"
              styleName="explorer-7"
              onMouseOver={e => mouseEnterImage(e)}
              onMouseLeave={e => mouseLeaveImage(e)}
            />
          </div>
        </div>
      </div>
      <div
        id="explorer-content"
        styleName="explorer-view-content  cssanimation"
      >
        {currentExplorer ? (
          <>
            <span
              styleName={`${explorersDetails[currentExplorer]?.module
                .replace(" ", "")
                .toLowerCase()}`}
            >
              {explorersDetails[currentExplorer].module}
            </span>
            <h3>{explorersDetails[currentExplorer].heading}</h3>
            <p>{explorersDetails[currentExplorer].desc}</p>
          </>
        ) : (
          <>
            <h3>Explore the 12 Explorers</h3>
            <p>
              All the intelligence you need to get to productivity in one place
            </p>
            <span>Tap the Explorers to find out more</span>
          </>
        )}
      </div>
    </div>
  )
}
export default ExplorerView
